import {
  autoUpdate,
  ClientRectObject,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import useResizeObserver from '@react-hook/resize-observer';
import { TFunction } from 'i18next';
import React, { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import { GeodataStatus } from '../../../../typings/api/skymap/rest/v0/.common';
import { WebsocketMessage } from '../../../../typings/websocket-messages';
import { angularGlobals } from '../../../js/core/config';
import { SubscriptionTopic } from '../../../js/messaging/pubsub';
import { formattedDateTime } from '../../../js/utils/dateUtils';
import { isDefined } from '../../../js/utils/variables';
import { WebsocketManager } from '../../../js/utils/websocket/websocket-manager';
import { useSubscribe } from '../../hooks/use-subscribe';
import { goToGcpPage } from '../cloud-processing/geodata-list/geodata-list-content';
import { Hyperlink, HyperlinkStyled } from '../hyperlink/hyperlink';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';

type NotificationBellStatus = 'connecting' | 'disconnected' | 'connected' | 'messageRecieved';

function resolveIconName(status: NotificationBellStatus): IconName {
  if (status === 'messageRecieved') {
    return 'bell-ring';
  }

  return 'bell';
}

function resolveColor(status: NotificationBellStatus, theme: DefaultTheme): string | undefined {
  if (status === 'disconnected') {
    return theme.color.red;
  }

  if (status === 'connecting') {
    return theme.color.gray.medium;
  }

  return undefined;
}

type NotificationItem = {
  id: string;
  timestamp: string;
  icon: ReactNode;
  content: ReactNode;
  onClick?: () => void | Promise<void>;
};

function shouldAutoOpenNotificationCenter(data: WebsocketMessage) {
  return data.message === 'BatchDownloadUpdated' && data.progress === 0;
}

function getFilteredNotifications(
  oldItems: NotificationItem[],
  data: WebsocketMessage,
  theme: DefaultTheme,
  t: TFunction,
): NotificationItem[] {
  const concatenateItems = (newItem: NotificationItem) => [
    newItem,
    ...oldItems.filter((x) => x.id !== newItem.id),
  ];

  if (data.message === 'GeodataUpdated' && isDefined(data.projectName)) {
    if (
      (data.geodataStatus as GeodataStatus) === 'user_move_flags' &&
      data.state === 'IDLE' &&
      data.gcpCorrectionRequested !== true
    ) {
      return concatenateItems({
        id: data.geodataId,
        timestamp: formattedDateTime(Date.now()),
        icon: (
          <Icon color={theme.color.blue} fixedWidth={true} icon={['fad', 'flag']} size={'xl'} />
        ),
        content: (
          <label>
            <Trans
              components={{
                hyperlink: (
                  <HyperlinkStyled
                    onClick={() => goToGcpPage({ id: data.geodataId, projectId: data.projectId })}
                  />
                ),
                bold: <strong />,
              }}
              i18nKey="notifications.processReadyForGcpAdjustment"
              ns="cloudProcessing"
              values={{
                geodataName: data.geodataName,
                projectName: data.projectName,
              }}
            />
          </label>
        ),
      });
    }

    if (isDefined(data.projectName) && data.state === 'FINISHED') {
      return concatenateItems({
        id: data.geodataId,
        timestamp: formattedDateTime(Date.now()),
        icon: (
          <Icon color={theme.color.green} fixedWidth={true} icon={['fad', 'drone']} size={'xl'} />
        ),
        content: (
          <label>
            <Trans
              components={{
                hyperlink: (
                  <HyperlinkStyled
                    onClick={() =>
                      angularGlobals.getState().go('sky.viewer', {
                        projectId: data.projectId,
                      })
                    }
                  />
                ),
                bold: <strong />,
              }}
              i18nKey="notifications.processFinished"
              ns="cloudProcessing"
              values={{
                geodataName: data.geodataName,
                projectName: data.projectName,
              }}
            />
          </label>
        ),
      });
    }
  }

  if (data.message === 'BatchDownloadCompleted') {
    return concatenateItems({
      id: data.batchDownloadId,
      timestamp: formattedDateTime(Date.now()),
      icon: (
        <Icon
          color={theme.color.green}
          fixedWidth={true}
          icon={['fas', 'check-circle']}
          size={'xl'}
        />
      ),
      content: (
        <label>
          <Trans
            components={{
              hyperlink: <Hyperlink target="_blank" url={data.downloadUrl} />,
            }}
            i18nKey="notifications.fileReady"
            ns="components"
            values={{
              fileName: data.fileName,
            }}
          />
        </label>
      ),
    });
  }

  if (data.message === 'BatchDownloadUpdated') {
    const hasFailed = data.status === 'FAILED';
    return concatenateItems({
      id: data.batchDownloadId,
      timestamp: formattedDateTime(Date.now()),
      icon: (
        <Icon
          color={hasFailed ? theme.color.red : theme.color.gray.medium}
          fixedWidth={true}
          icon={hasFailed ? ['fas', 'exclamation-circle'] : ['fas', 'spinner']}
          size={'xl'}
          spin={!hasFailed}
        />
      ),
      content: hasFailed ? (
        <label>
          <Trans
            components={{
              bold: <strong />,
            }}
            i18nKey="notifications.fileError"
            ns="components"
            values={{
              fileName: data.fileName,
            }}
          />
        </label>
      ) : (
        <label>
          <Trans
            components={{
              bold: <strong />,
            }}
            i18nKey="notifications.fileProcessing"
            ns="components"
            values={{
              fileName: data.fileName,
              progress: (data.progress * 100).toFixed(0),
            }}
          />
        </label>
      ),
    });
  }

  return oldItems;
}
const isWithinRect = (
  event: { clientX?: number; clientY?: number },
  rect: DOMRect | ClientRectObject | undefined,
) => {
  if (!isDefined(rect) || !isDefined(event?.clientX) || !isDefined(event?.clientY)) {
    return false;
  }

  return (
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom
  );
};

export const NotificationBellIcon = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [status, setStatus] = useState<NotificationBellStatus>(WebsocketManager.instance.status);
  const [notificationListOpen, setNotificationListOpen] = useState(false);
  const [_messages, setMessages] = useState<WebsocketMessage[]>([]);
  const [items, setItems] = useState<NotificationItem[]>([]);

  const { refs, strategy, floatingStyles, update } = useFloating({
    middleware: [offset(), flip(), shift()],
    placement: 'bottom-end',
  });

  useSubscribe(SubscriptionTopic.WebsocketStatusChanged, (message) => {
    setStatus(message.status);
  });

  useSubscribe(SubscriptionTopic.WebsocketMessageRecieved, (data) => {
    setStatus('messageRecieved');
    setMessages((oldValue) => {
      return [...oldValue, data];
    });
    setItems((oldItems) => getFilteredNotifications(oldItems, data, theme, t));

    if (shouldAutoOpenNotificationCenter(data)) {
      setNotificationListOpen(true);
    }
  });

  useResizeObserver(document.body, () => {
    if (refs.reference.current && refs.floating.current) {
      autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  });

  useOnClickOutside(refs.floating as React.RefObject<HTMLElement>, (e) => {
    if (
      notificationListOpen &&
      !isWithinRect(e as globalThis.MouseEvent, refs.reference.current?.getBoundingClientRect())
    ) {
      setNotificationListOpen(false);
    }
  });

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (status === 'disconnected') {
        WebsocketManager.instance.connect(1);
        return;
      }
      if (status === 'messageRecieved') {
        setStatus(WebsocketManager.instance.status);
      }

      if (isWithinRect(e, refs.reference.current?.getBoundingClientRect())) {
        setNotificationListOpen((state) => !state);
      }
    },
    [status, refs.reference],
  );

  return (
    <Component highlighted={notificationListOpen} ref={refs.setReference} onClick={onClick}>
      <IconContainer>
        <Icon
          beatFade={status === 'connecting'}
          color={resolveColor(status, theme)}
          fixedWidth={true}
          icon={['fas', resolveIconName(status)]}
          size={'xl'}
          title={'Notifieringar'}
          onHoverStyle={{ icon: ['fad', resolveIconName(status)] }}
        />
        {items.length > 0 && <MessageCountLabel status={status}>{items.length}</MessageCountLabel>}
      </IconContainer>

      {notificationListOpen && (
        <FloatingPortal>
          <NotificationList
            className="notranslate"
            ref={refs.setFloating}
            style={{ ...floatingStyles, position: strategy }}
          >
            <NotificationsHeader alignItems="center" direction="row" justifyContent="space-between">
              <label>{t('notifications.title', { ns: 'components' })}</label>
              <HyperlinkStyled onClick={() => setItems([])}>
                {t('notifications.clearAll', { ns: 'components' })}
              </HyperlinkStyled>
            </NotificationsHeader>

            {items.length === 0 && (
              <NotificationCard alignItems="center" direction="column" spacing={0.5}>
                <Icon
                  color={theme.color.gray.dark}
                  fixedWidth={true}
                  icon={['fad', 'magnifying-glass']}
                  size={'xl'}
                />
                <NoNewNotifications>
                  {t('notifications.noNewNotifications', { ns: 'components' })}
                </NoNewNotifications>
              </NotificationCard>
            )}

            <Stack direction="column">
              {items.map((item, idx) => (
                <NotificationCard direction="column" key={idx} spacing={0.5}>
                  <TimeStamp>{item.timestamp}</TimeStamp>
                  <Stack alignItems="center" direction="row" spacing={0.5}>
                    {item.icon}
                    <CardContent>{item.content}</CardContent>
                    <Icon
                      color={theme.color.gray.dark}
                      fixedWidth={true}
                      icon={['fas', 'xmark']}
                      size={'xl'}
                      onClick={() =>
                        setItems((oldItems) => oldItems.filter((x) => x.id !== item.id))
                      }
                      onHoverStyle={{ icon: ['fad', 'xmark'] }}
                    />
                  </Stack>
                </NotificationCard>
              ))}
            </Stack>
          </NotificationList>
        </FloatingPortal>
      )}
    </Component>
  );
};

const Component = styled.div<{ highlighted: boolean }>`
  color: var(--top-menu-text-color);
  padding: 0 15px;
  position: relative;
  align-content: center;
  height: 100%;
  &:hover {
    background-color: ${(props) => props.theme.color.gray.darkest};
  }
  background-color: ${(props) => (props.highlighted ? props.theme.color.gray.darkest : 'unset')};
`;

const IconContainer = styled.div`
  position: relative;
`;

const MessageCountLabel = styled.label<{ status: NotificationBellStatus }>`
  position: absolute;
  font-size: 0.75em;
  text-align: center;
  min-width: 1em;
  color: white;
  background-color: ${(props) =>
    props.status === 'messageRecieved' ? 'red' : props.theme.color.gray.medium};
  border-radius: 1em;
  bottom: -0.3em;
  right: -0.3em;
`;

const NotificationList = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  overflow-y: auto;
  max-height: 350px;
  z-index: 1000;
  width: 350px;
  box-sizing: border-box;
`;

const CardContent = styled.div`
  flex: 1;
`;

const NotificationsHeader = styled(Stack)`
  padding: 1em 0.5em;
  color: ${(props) => props.theme.color.gray.dark};
  user-select: none;
  border-bottom: 1px solid ${(props) => props.theme.color.gray.lightest};
`;

const NoNewNotifications = styled.label`
  color: ${(props) => props.theme.color.gray.medium};
  user-select: none;
`;

const TimeStamp = styled.div`
  color: ${(props) => props.theme.color.gray.medium};
  font-size: 0.75em;
`;

const NotificationCard = styled(Stack)`
  position: relative;
  padding: 0.5em;
  background-color: white;
  border: 1px solid ${(props) => props.theme.color.gray.lightest};
  &:hover {
    background-color: ${(props) => props.theme.color.brand.lightest};
  }
`;
