import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { v4 } from 'uuid';

import { TokenService } from '../../../../js/services/authentication/token.service';
import {
  FileUpload,
  UploadManuallyStoppedError,
} from '../../../../js/services/network/file-upload';
import { ProjectStore } from '../../../../js/stores/project-store';
import { isDefined } from '../../../../js/utils/variables';
import { WebApi } from '../../../../js/webAPI/web-api';
import { useErrorHandling } from '../../../hooks/use-error-handling';
import { Button } from '../../button/button';
import { CheckBox } from '../../check-box/check-box';
import { Dialog } from '../../dialog/dialog';
import { DropdownSuggestion } from '../../dropdown-suggestion/dropdown-suggesion';
import { FileSelectionButton } from '../../file-selection-button/file-selection-button';
import { LabelledContainer } from '../../labelled-container/labelled-container';
import { ProgressBar } from '../../progress-bar/progress-bar';
import {
  CoordinateSystemItem,
  useCoordinateSystemsAndGeoidsQuery,
} from '../../project-settings/projection';
import { Stack } from '../../stack/stack';
import { TooltipInfoIcon } from '../../tooltip-info-icon/tooltip-info-icon';
import { ManualUploadItem, ManualUploadParams } from './skyview-data';

interface Props {
  accept: string;
  title: string;
  showZUpCheckBox?: boolean;
  showCoordinateSystem?: boolean;
  showGeoids?: boolean;
  onClose: (item?: ManualUploadItem) => void;
  onUploaded: (item: ManualUploadParams) => Promise<ManualUploadItem>;
}

enum Status {
  Idle,
  Pending,
  Error,
}

const noGeoidId = v4();

type ApiRequestPath = 'body.uploadId';

const NewUploadDialog = (props: Props) => {
  const { t } = useTranslation();
  const [status, setStatus] = React.useState(Status.Idle);
  const [progress, setProgress] = React.useState(0);
  const [file, setFile] = React.useState<File>();
  const [zUp, setZUp] = React.useState<boolean>(false);
  const [selectedCoordinateSystem, setSelectedCoordinateSystem] = React.useState<
    CoordinateSystemItem | undefined
  >();
  const [selectedGeoid, setSelectedGeoid] = React.useState<CoordinateSystemItem | undefined>();
  const [geoids, setGeoids] = React.useState<CoordinateSystemItem[]>([]);
  const [coordinateSystems, setCoordinateSystems] = React.useState<CoordinateSystemItem[]>([]);
  const { handleError, clearErrors, buildErrorList, includeErrorPaths } =
    useErrorHandling<ApiRequestPath>();
  const upload = React.useRef<FileUpload>();

  const { data, isLoading } = useCoordinateSystemsAndGeoidsQuery();

  useEffect(() => {
    if (!isDefined(data)) {
      return;
    }

    const projectCoordinateSystem = {
      id: ProjectStore.instance.coordinateSystem!.id,
      name: `${ProjectStore.instance.coordinateSystem!.name} (${ProjectStore.instance.coordinateSystem!.authority})`,
      countryId: null,
    };

    const projectGeoid = isDefined(ProjectStore.instance.geoid)
      ? {
          id: ProjectStore.instance.geoid.id,
          name: `${ProjectStore.instance.geoid.name}`,
          countryId: null,
        }
      : undefined;

    const noGeoid: CoordinateSystemItem = {
      countryId: null,
      id: noGeoidId,
      name: t('notSelected', { ns: 'common' }),
    };

    setSelectedCoordinateSystem(projectCoordinateSystem);
    setSelectedGeoid(projectGeoid ?? noGeoid);

    setCoordinateSystems(
      uniq(
        [
          projectCoordinateSystem,
          ...data.coordinateSystems.filter((x) => x.id !== projectCoordinateSystem.id),
        ],
        false,
        (x) => x.id,
      ),
    );

    const allGeoids = uniq(
      data.geoids.filter((x) => x.id !== projectGeoid?.id),
      false,
      (x) => x.id,
    );

    if (projectGeoid) {
      allGeoids.unshift(projectGeoid);
    }

    allGeoids.unshift(noGeoid);

    setGeoids(allGeoids);
  }, [data, t]);

  const onFileAdded = useCallback(
    async (params: {
      file: File;
      coordinateSystemId?: string;
      isZUp?: boolean;
      geoidId?: string;
    }) => {
      try {
        setStatus(Status.Pending);
        clearErrors();
        setFile(params.file);

        upload.current = new FileUpload({
          apiBaseUrl: WebApi.getUrl(),
          authToken: new TokenService().get(),
          file: params.file,
          concurrency: 3,
        });

        upload.current.onProgress((bytes) => setProgress(bytes / params.file.size));
        const result = await upload.current.start();

        const uploadId = result.id;
        const newItem = await props.onUploaded({
          filename: params.file.name,
          uploadId: uploadId,
          isZUp: params.isZUp,
          coordinateSystemId: params.coordinateSystemId,
          geoidId: params.geoidId,
        });

        props.onClose(newItem);
      } catch (err: unknown) {
        setStatus(Status.Error);
        if (err instanceof UploadManuallyStoppedError) {
          return;
        }

        handleError(err);
      } finally {
        setProgress(0);
      }
    },
    [clearErrors, handleError, props],
  );

  return (
    <>
      <Dialog
        closeIcon={status !== Status.Pending}
        closeOnDimmerClick={status !== Status.Pending}
        useGoogleTranslate={true}
        width={500}
        onClose={props.onClose}
      >
        {{
          header: `Uppladdning - ${props.title}`,
          content: (
            <Stack direction="column" spacing={1}>
              {status !== Status.Pending && (
                <Text topMargin={false}>Välj en fil för att starta uppladdning.</Text>
              )}

              {props.showCoordinateSystem && (
                <LabelledContainer
                  text={t('projectSettings.projection.title', { ns: 'components' })}
                >
                  <DropdownSuggestion
                    disabled={
                      status === Status.Pending || isLoading || !isDefined(selectedCoordinateSystem)
                    }
                    placeholder={selectedCoordinateSystem?.name}
                    suggestions={coordinateSystems}
                    value={selectedCoordinateSystem?.id}
                    width="100%"
                    onSuggestionSelected={(suggestion) => setSelectedCoordinateSystem(suggestion)}
                  />
                </LabelledContainer>
              )}
              {props.showGeoids && (
                <LabelledContainer
                  text={t('projectSettings.projection.geoidModel', { ns: 'components' })}
                >
                  <DropdownSuggestion
                    disabled={status === Status.Pending || isLoading || !isDefined(selectedGeoid)}
                    placeholder={selectedGeoid?.name}
                    suggestions={geoids}
                    value={selectedGeoid?.id}
                    width="100%"
                    onSuggestionSelected={(suggestion) => setSelectedGeoid(suggestion)}
                  />
                </LabelledContainer>
              )}

              {props.showZUpCheckBox && (
                <Stack direction="row" spacing={0.5}>
                  <CheckBox
                    checked={zUp}
                    disabled={status === Status.Pending}
                    onChange={() => setZUp(!zUp)}
                  >
                    Använd Z som vertikal axel
                  </CheckBox>
                  <TooltipInfoIcon
                    content={
                      <div>
                        Kryssa i för att processera modellen med Z som den vertikala axeln.
                        <br />
                        <br />
                        Y-axeln representerar upp/ner i de flesta Cesium 3D Tiles-modeller. Om din
                        modell är manuellt konverterad kan Z-axeln representera upp/ner istället.
                        <br />
                        <br />
                        Lämna urbockad om du är osäker.
                      </div>
                    }
                    placement="top"
                    title="Använd Z som vertikal axel"
                    useGoogleTranslate={true}
                  />
                </Stack>
              )}

              {status === Status.Pending && (
                <Stack direction="column" spacing={1}>
                  <span>
                    Laddar upp <b>{file!.name}</b>...
                  </span>
                  <ProgressBar
                    height={'2em'}
                    max={1}
                    maxPercentage={99}
                    min={0}
                    value={progress}
                    width={'100%'}
                  />
                </Stack>
              )}

              {buildErrorList({
                filter: includeErrorPaths(['body.uploadId']),
                defaultMessage: 'Det gick inte att ladda upp filen.',
              })}
            </Stack>
          ),
          footer: {
            right: (
              <>
                <FileSelectionButton
                  accept={props.accept}
                  color="primary"
                  disabled={status === Status.Pending}
                  leftIcon={{ icon: ['fad', 'upload'] }}
                  multiple={false}
                  text="Välj fil"
                  variant="contained"
                  onFilesSelected={async (files) => {
                    if (files.length > 0) {
                      await onFileAdded({
                        file: files[0],
                        coordinateSystemId: selectedCoordinateSystem?.id,
                        geoidId: selectedGeoid?.id !== noGeoidId ? selectedGeoid?.id : undefined,
                        isZUp: zUp,
                      });
                    }
                  }}
                />

                <Button
                  variant="text"
                  onClick={() => {
                    upload.current?.stop();
                    props.onClose();
                  }}
                >
                  Avbryt
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

export { NewUploadDialog };
